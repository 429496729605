import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-projects.png'
import MainMessage from '@/components/common/MainMessage'
import PostList from '@/components/modules/Project/PostList'
import List from '@/components/modules/Project/List'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const ProjectsIndexPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      return (
        <Root>
          <SEO location={props.location} title="受託開発実績" description="株式会社ウィザードの開発実績の紹介。卓越した技術とイメージを共有できる技術者が、業務系からコンシューマ系まで、様々な分野の要望にお応えします。"/>
          <LD json={{
            '@type': 'CreativeWork',
            'name': '受託開発実績一覧',
            'description': '株式会社ウィザードの開発実績の紹介。卓越した技術とイメージを共有できる技術者が、業務系からコンシューマ系まで、様々な分野の要望にお応えします。',
            author: LDEntity.Corporation(siteMeta, company)
          }}/>
          <Head>
            <PageTitle src={imgTitle}>受託開発実績</PageTitle>
            <MainMessage
              body={(
                <Fragment>これまでの開発実績です。卓越した技術とイメージを共有できる技術者が、業務系からコンシューマ系まで、様々な分野の要望にお応えします。</Fragment>
              )}
            />
          </Head>
          <Main>
            <Row css={styles.PostList}><PostList /></Row>
            <Row><List /></Row>
            <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
            <Row css={styles.Contact}><ContactLead /></Row>
            <Row css={styles.Recruit}><RecruitLead /></Row>
          </Main>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div`
`

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  PostList: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(ProjectsIndexPage)

const query = graphql`
  query ProjectsPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
  }
`
